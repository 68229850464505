import React from 'react'
import './index.css'

export default function Footer() {
  return (
    <>
      <footer className='footer'>
        <p> © 2019-2022 <a href="">Zsj Blog</a> 版权所有 ICP证： <a href="https://beian.miit.gov.cn" target="_blank">豫ICP备19013573号-1</a></p>
      </footer>
    </>

  )
}


// blog
export const GET_BLOG_LIST = 'getBlogList';
export const GET_BLOG_DETAIL = 'getBlogDetail';
export const GET_BLOG_COMMENT = 'getBlogComment';
export const GET_BLOG_RECOMMEND = 'getBlogRemmend';
export const GET_BLOG_TYPE = 'getBlogType';

export const UPDATE_IS_REPLAY = 'updateIsReplay';

export const CLEAR_BLOG_LIST = 'clearBlogList';

// login
export const UPDATE_IS_SHOW_LOGIN = 'updateIsShowLogin';

// sys
export const GET_BLOG_TAG = 'getBlogTag';

